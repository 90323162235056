.newsDate {
  text-decoration: none;
  font-size: 13px;
  color:rgba(82, 82, 82, 0.692);
}

.rightBorder {
  border-right: rgba(82, 82, 82, 0.5) 2px solid;
}

.newsEntryContent {
  text-decoration: none;
  font-size: 13px;
  color:rgba(82, 82, 82, 0.692);
  text-align: justify;
}

.newsContent {
  text-decoration: none;
  font-size: 13px;
  color:rgba(82, 82, 82, 0.692);
}

.newsTitle {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color:rgb(0, 0, 0);
}

.newshow-image {
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.newshow-thumb {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 25px;
  cursor: pointer;
}

.desktopView {
  display: block;
}

.mobileView {
  display: none;
}

@media (max-width: 768px) { 
  .rightBorder {
    border-right: none !important;
  }

  .newshow-image {
    height: auto;
    width: 100%;
  }

  .desktopView {
    display: none !important;
  }

  .mobileView {
    display: block !important;
  }
}