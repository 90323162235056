.sobreNosotros {
  margin-bottom: 15px;
}

.sobreNosotrosText {
  font-family: var(--primary-family);
  text-align: left;
}

.sobreNosotrosSubHeader {
  font-family: var(--primary-family);
  font-size: 40px;
  color: var(--primary-color);
  text-shadow: 2px 2px grey;
  text-align: left;
}

.sobreNosotrosImages {
  display: flex;
}

.sobreNosotrosImg {
  height: 150px;
  width: auto;
  margin: 5px 5px;
  border-radius: 5px;
  box-shadow: 3px 3px grey;
}

.sobreNosotrosImg:hover {
  transform: scale(1.5);
}

@media (max-width: 545px) {
  .sobreNosotrosText {
    font-family: var(--primary-family);
    text-align: center;
    margin-top: -50px;
  }

  .sobreNosotrosImg {
    display: inline-block;
    margin: 0 auto;
    height: 100px;
    width: auto;
    margin: 5px 5px;
    border-radius: 5px;
    box-shadow: 3px 3px grey;
  }
}
