.hero {
  background-image: url("../General/Pictures/Kulturhuset_background.webp");
  height: 80vh;
  width: 100%;
  background-size: cover;
  padding-bottom: 0;
  top: -12px !important;
  position: relative;
  margin-bottom: 25px;
  border-radius: 5px;
}

@media (max-width: 545px) {
  .hero {
    width: 100%;
    height: 150px;
  }
}
