.projectbox-text {
  background-color: #000000;
  font-weight: 500;
  color: #fff;
  height: 30px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  padding-top: 2px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.projectshow-div {
  width: 100%;
  height: auto;
}

.projectshow-image {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.projectshow-thumb {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 25px;
  cursor: pointer;
}

.projectbox-div {
  background-color: #000000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 2px solid var(--primary-color);
  padding: 10px;
  color: grey;
  width: 100%;
  height: 250px;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;;
}

.projectCategory-div {
  text-align: center;
}

.projectCategory-div a, span {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
}

.projectCategory-link-div {
 display: inline;
}

.projectCategory-link {
  color: #000;
}

.projectCategory-link-active {
  color: rgba(179, 179, 179, 0.692) !important;
}

@media (max-width: 768px) {
  .projectshow-image {
  }
}