@font-face {
  font-family: "ARLRDBD";
  src: local("ARLRDBD"), url("./Assets/Fonts/ARLRDBD.ttf") format("truetype");
}

:root {
  --primary-color: #ffffff;
  --secondary-color: #000000;
  --font-color: #000000;
  --primary-family: "ARLRDBD";
  --secondary-family: "ARLRDBD";
  --third-family: "Arial";
}

.btn {
  background-color: var(--primary-color) !important;
  font-family: var(--secondary-family) !important;
  box-shadow: 3px 3px grey;
  color: #fff !important;
}

.btn:hover {
  color: #000 !important;
}

a,
a:link {
  transition: all 300ms ease-in-out !important;
}