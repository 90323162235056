.App {
  background-color:#ffffff;
  min-height: 100vh;
  margin-left: 50px;
  margin-right: 50px;
}

.container-fluid {
  --bs-gutter-x: 0rem !important;
}

.container {
  padding: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  margin-bottom: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}